import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChargeRepaySubscriptionResponse } from '@chargeRepay/charge-repay.interfaces'
import { HttpBaseService } from '@services/http-base.service'
import {
  AvailableEndpointMetricsResponse,
  BillingAccount,
  BookingPackage,
  CreateSubscriptionResponse,
  IaSubscription,
  ImpulsePackagesResponse,
  SimplePackage,
  SubscriptionResponse,
  TsdPackagesResponse,
  TsdSubscription,
} from '@services/proficloud.interfaces'
import { AppService } from 'src/app/app.service'

@Injectable({
  providedIn: 'root',
})
export class BillingHttpService extends HttpBaseService {
  constructor(
    protected override app: AppService,
    private httpClient: HttpClient
  ) {
    super(app)
  }

  public getBillingAccount() {
    const url = this.backendUrls.billingBaseUrl + '/billingAccount'
    return this.httpClient.get<BillingAccount>(url)
  }

  public createBillingAccount(billingAccountData: BillingAccount) {
    const url = this.backendUrls.billingBaseUrl + '/billingAccount'
    return this.httpClient.post<BillingAccount>(url, billingAccountData)
  }

  public updateBillingAccount(billingAccountData: BillingAccount) {
    const url = this.backendUrls.billingBaseUrl + '/billingAccount'
    return this.httpClient.put<BillingAccount>(url, billingAccountData)
  }

  public deleteBillingAccount() {
    const url = this.backendUrls.billingBaseUrl + '/billingAccount'
    return this.httpClient.delete(url)
  }

  public createSubscription(simplePackage: SimplePackage) {
    const url = this.backendUrls.billingBaseUrl + '/v2/subscriptions'
    return this.httpClient.post<CreateSubscriptionResponse>(url, simplePackage)
  }

  public upgradeSubscription(subscriptionId: string, bookingPackage: SimplePackage) {
    const url = this.backendUrls.billingBaseUrl + `/v2/subscriptions/${subscriptionId}/upgrade`
    return this.httpClient.post(url, bookingPackage) // post instead of put?
  }

  public downgradeSubscription(subscriptionId: string, bookingPackage: SimplePackage) {
    const url = this.backendUrls.billingBaseUrl + `/v2/subscriptions/${subscriptionId}/downgrade`
    return this.httpClient.post(url, bookingPackage) // post instead of put?
  }

  public upgradeSubscriptionPreview(subscriptionId: string, bookingPackage: SimplePackage) {
    const url = this.backendUrls.billingBaseUrl + `/subscriptions/${subscriptionId}/upgrade/preview`
    return this.httpClient.post(url, bookingPackage) // post instead of put?
  }

  public listAvailableTsdPackages() {
    const url = this.backendUrls.tsdServiceUrl + '/billing/packages'
    return this.httpClient.get<TsdPackagesResponse>(url)
  }

  public listAvailableImpulsePackages() {
    const url = this.backendUrls.iaServiceUrl + '/billing/packages'
    return this.httpClient.get<ImpulsePackagesResponse>(url)
  }

  public listTsdSubscriptions() {
    const url = this.backendUrls.tsdServiceUrl + '/billing/subscriptions'
    return this.httpClient.get<TsdSubscription[]>(url)
  }

  public listIaSubscriptions() {
    const url = this.backendUrls.iaServiceUrl + '/billing/subscriptions'
    return this.httpClient.get<IaSubscription[]>(url)
  }

  public getEmsSubscriptions() {
    const url = `${this.backendUrls.emmaUrl}/emma-subscription/api/v1/subscriptions/`
    return this.httpClient.get<SubscriptionResponse[]>(url)
  }

  public listChargeRepaySubscriptions() {
    const url = this.backendUrls.chargeRepayUrl + '/v1/billing/subscriptions'
    return this.httpClient.get<ChargeRepaySubscriptionResponse>(url)
  }

  public listAvailableEndpointMetrics(deviceEndpointId: string) {
    const url = this.backendUrls.tsdServiceUrl + '/billing/available/metrics/' + deviceEndpointId
    return this.httpClient.get<AvailableEndpointMetricsResponse>(url)
  }

  public setUsedMetrics(subscriptionId: string, payload: { metrics: [Record<string, any>] }) {
    const url = this.backendUrls.tsdServiceUrl + '/billing/used/metrics/' + subscriptionId
    return this.httpClient.post<TsdSubscription>(url, payload)
  }

  public bulkAssignMetrics(subscriptionId: string, payload: any) {
    // TODO: Typing
    const url = this.backendUrls.tsdServiceUrl + '/billing/used/metrics/' + subscriptionId
    return this.httpClient.post<TsdSubscription>(url, payload)
  }

  public setImpulseAssignedDevices(subscriptionId: string, endpointIds: string[]) {
    const url = this.backendUrls.iaServiceUrl + `/billing/used/devices/` + subscriptionId

    const payload = {
      devices: endpointIds,
    }
    return this.httpClient.post(url, payload) // TODO: have a look what response is (doesn't seem to matter but still)
  }

  public listAllSubscriptions() {
    const url = this.backendUrls.billingBaseUrl + '/subscriptions'
    return this.httpClient.get<SubscriptionResponse[]>(url)
  }

  public cancelSubscription(subscriptionId: string) {
    const url = this.backendUrls.billingBaseUrl + `/subscriptions/${subscriptionId}`
    return this.httpClient.delete<SubscriptionResponse>(url)
  }

  public listPackagesPerService(serviceId: 'tsd' | 'impulseanalytics' | 'emma' | 'crs' | 'ems') {
    // TODO: Got some disagreement with service ids
    const url = `${this.backendUrls.billingBaseUrl}/${serviceId}/bookingpackages`
    return this.httpClient.get<BookingPackage[]>(url)
  }
}
