// angular
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subject, Subscription } from 'rxjs'
// rxjs
import { filter, take } from 'rxjs/operators'
import { AppService } from 'src/app/app.service'
import {
  AcceptableBillingCountryCodes,
  AssignDevicesModalConfig,
  BillingAccount,
  BookingPackage,
  Device,
  Environments,
  IaSubscription,
  ImpulsePackagesResponseItem,
  PCOverlayConfig,
  ProficloudInputConfig,
  SelectSubscriptionModalConfig,
  ServiceIds,
  ShoppingCart,
  SubscriptionResponse,
  TsdMetric,
  TsdPackagesResponseItem,
  TsdSubscription,
  UpgradePreview,
} from '../proficloud.interfaces'
// app
import { ChargeRepaySubscription } from '@chargeRepay/charge-repay.interfaces'
import { TranslateService } from '@ngx-translate/core'
import { DeviceBillingService, DeviceBillingSubscription } from '../../modules/device-management/services/device-billing.service'
import { DeviceService } from '../../modules/device-management/services/device.service'
import { DeviceStore } from '../../modules/device-management/stores/device.store'
import { BillingStore } from '../../stores/billing.store'
import { AuthorisationService } from '../authorisation.service'
import { ContentService } from './../content/content.service'
import { ProficloudService } from './../proficloud.service'
import { ContentfulPlanVariant, ContentfulService } from './contentful-interfaces'

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  /**
   *    Streams
   */
  showBillingAddressRequired$ = new Subject<boolean>()

  showBillingAddressForm$ = new Subject<boolean>()

  showCheckoutForm$ = new Subject<boolean>()

  showConfirmationForm$ = new Subject<boolean>()

  showUpgradeConfirmationForm$ = new Subject<boolean>()

  showAuthRequired$ = new Subject<boolean>()

  showContactRequired$ = new Subject<boolean>()

  showContactRequiredEms$ = new Subject<boolean>()

  showUpgradeDetails$ = new Subject<boolean>()

  // assign devices or metric modals
  showAssignDevicesModal$ = new Subject<AssignDevicesModalConfig | false>()

  showAssignMetricsModal$ = new Subject<boolean | Device>()

  showSelectSubscriptionModal$ = new Subject<SelectSubscriptionModalConfig | false>()

  // cancellations
  showCancelSubscriptionConfirm$ = new Subject<
    | {
        subscription: TsdSubscription | IaSubscription | DeviceBillingSubscription | ChargeRepaySubscription | SubscriptionResponse // EMMA
        type: ServiceIds
      }
    | false
  >()

  /**
   *    Data
   */

  links = {
    termsAndConditions: 'https://proficloud.io/terms-and-conditions/',
    softwareLicenseTerms: 'https://proficloud.io/software-license-terms-international/',
  }

  // tsd subscription (single)
  tsdSubscription: TsdSubscription

  tsdTotalMetricsUsed = 0

  tsdFlatArray: TsdMetric[] = []

  bookedSubscription: SubscriptionResponse

  upgradedSubscription: SubscriptionResponse

  // impulseAnalytics (multiple)
  impulseAnalyticsSubscriptions: IaSubscription[] = []
  totalIASlots: number
  assignedIASlots: number

  // Emma Subscriptions (multiple)
  emsSubscriptions: SubscriptionResponse[] = []
  allEMMASubscriptions: SubscriptionResponse[] = []

  emmaSubscriptionsError = false

  // charge repay
  chargeRepaySubscriptions: ChargeRepaySubscription[] = []

  chargeRepayPackage: BookingPackage

  chargeRepayStats: { totalSeats: number; usedSeats: number; freeSeats: number }

  countryMapping: { [key in AcceptableBillingCountryCodes]: string } = {
    DE: 'Germany',
    CH: 'Switzerland',
    IT: 'Italy',
    ES: 'Spain',
    CL: 'Chile',
    US: 'USA',
    // CA: 'Canada'
  }
  stripeCountries: string[] = [
    'US',
    // 'CA'
  ]

  currentCountry: AcceptableBillingCountryCodes = 'DE'

  billingDetails: {
    shoppingCart?: ShoppingCart
    upgradePreview?: UpgradePreview
  } = {}

  upgradeService: ContentfulService

  /**
   *    loaded from billing service
   */
  billingAccount: BillingAccount | null = null

  /**
   *   Dialog Config
   */

  billingAddressDialog: PCOverlayConfig = {
    title: 'Billing Data',
  }

  // fields
  billingFormPersonalFields: ProficloudInputConfig[]

  billingFormCompanyFields: ProficloudInputConfig[]

  // forom
  billingAddressForm: UntypedFormGroup

  availableIaPackages: ImpulsePackagesResponseItem[]

  availableTsdPackages: TsdPackagesResponseItem[]

  bookedTsdPackage?: TsdPackagesResponseItem

  emmaPackages: BookingPackage[]

  iaCapableDevices: Device[] = []

  tsdCapableDevices: Device[] = []

  dmsBaSubscriptions: SubscriptionResponse[]

  devicesListedSubscription: Subscription

  hideCustomerNumberFieldControl: UntypedFormControl = new UntypedFormControl(false)

  constructor(
    // angular
    public router: Router,
    // app
    public app: AppService,
    public authorization: AuthorisationService,
    private billingStore: BillingStore,
    public content: ContentService,
    public deviceBilling: DeviceBillingService,
    private deviceService: DeviceService,
    private deviceStore: DeviceStore,
    public proficloud: ProficloudService,
    private translationService: TranslateService
  ) {
    // for developing
    ;(window as any).billing = this

    // do nothing for non-authenticated users
    if (!proficloud.keycloakData.session) {
      return
    }

    this.deviceStore.storeInitialised$.pipe(take(2)).subscribe((errored) => {
      if (!errored) {
        this.resetCapableDevices()
        this.setAndCountTsdMetrics()
      }
    })

    this.billingStore.storeInitialised$.subscribe((res) => {
      if (!res) {
        this.setupReactionToDevicesAndSubscriptionsListed()
      }
    })

    // Billing Store Subscriptions
    this.billingStore.allAvailablePackages$.subscribe((res) => {
      this.availableTsdPackages = res.tsdPackages
      this.bookedTsdPackage = this.availableTsdPackages[0]
      this.availableIaPackages = res.iaPackages
      this.emmaPackages = res.emsPackages
      this.chargeRepayPackage = res.crsPackages[0]
    })

    this.billingStore.billingAccount$.subscribe({
      next: (billingAccount) => {
        this.billingAccount = billingAccount
        this.currentCountry = billingAccount?.address.country || 'DE'

        if (this.billingAccount?.pxcCustomerId) {
          this.hideCustomerNumberFieldControl.setValue(false)
        }

        // If the companyName is set it means we already have an billing account set up.
        // Now if at the same time the pxcCustomerId is not set it means the account was created without pxcCustomerId.
        if (this.billingAccount?.companyName && !this.billingAccount?.pxcCustomerId) {
          this.hideCustomerNumberFieldControl.setValue(true)
        }
      },
      error: (error: HttpErrorResponse) => {
        this.proficloud.logoutOnUnauthorised(error)
        if (error.status === 404) {
          this.billingAccount = null
          this.hideCustomerNumberFieldControl.setValue(false)
        }

        // Exception: Billing account not found: HttpStatus = NOT FOUND
        console.log('billing account error', error)
      },
    })

    this.content.countriesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe(() => {
      this.createBillingAddressForm()
    })

    this.billingStore.allSubscriptions$.subscribe({
      next: (res) => {
        // Note: First one down the pipe will be empty
        if (!res.allSubscriptions || !res.chargeRepaySubscriptions || !res.emsSubscriptions || !res.iaSubscriptions || !res.tsdSubscriptions) {
          return
        }

        // TSD
        this.tsdSubscription = res.tsdSubscriptions[0]

        if (this.tsdSubscription) {
          this.setAndCountTsdMetrics()
        }

        // Impulse Analytics
        if (res.iaSubscriptions && res.iaSubscriptions.length > 0) {
          this.impulseAnalyticsSubscriptions = res.iaSubscriptions
          this.totalIASlots = res.iaSubscriptions.map((sub) => sub.quantity).reduce((a, b) => a + b, 0)
          this.assignedIASlots = res.iaSubscriptions.map((sub) => sub.devicesUsed.length).reduce((a, b) => a + b)
        } else {
          this.impulseAnalyticsSubscriptions = []
          this.totalIASlots = 0
          this.assignedIASlots = 0
        }

        // Charge Repay
        this.chargeRepaySubscriptions = res.chargeRepaySubscriptions
        this.chargeRepayStats = this.chargeRepaySubscriptions.reduce(
          (acc, curr) => {
            acc.totalSeats += curr.seatsMaxCount
            acc.freeSeats += curr.seatsFreeCount
            acc.usedSeats += curr.seatsUsed.length
            return acc
          },
          { totalSeats: 0, freeSeats: 0, usedSeats: 0 }
        )

        // DMS Basic Addon
        this.dmsBaSubscriptions = res.allSubscriptions.filter((sub) => {
          return sub.smartServiceId == 'dmsAddonBasic'
        })

        // EMS
        this.allEMMASubscriptions = res.emsSubscriptions
        this.emsSubscriptions = res.emsSubscriptions.filter((s) => s.smartServiceId === 'ems')
      },
    })

    // create form
    this.content.countriesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe(() => {
      this.createBillingAddressForm()
    })

    // listen for checkout modal -> write to data layer
    this.reactToCheckoutModal()

    this.proficloud.organisationSwitchBegun$.subscribe(() => {
      this.clearSubscriptions()
    })
  }

  public resetCapableDevices() {
    // make an arrays of capabale devices
    this.iaCapableDevices = this.deviceService.devices.filter((device) => this.deviceService.deviceIsIaCapable(device)) || []
    this.tsdCapableDevices = this.deviceService.devices.filter((device) => this.deviceService.deviceIsTsdCapable(device)) || []

    // initially, set all tsd capabale device's used and available metrics to an empty array.
    this.deviceService.devices
      ?.filter((device) => this.deviceService.deviceIsTsdCapable)
      .forEach((device) => {
        device.availableMetrics = []
      })
  }

  private clearSubscriptions() {
    this.emsSubscriptions.length = 0
  }

  private setupReactionToDevicesAndSubscriptionsListed() {
    // there should always be exactely 1 tsd subscription and thus a package always
    const packId = this.tsdSubscription.bookedPackageId
    this.bookedTsdPackage = this.availableTsdPackages.find((pack) => pack.id === packId)
  }

  public setAllAvailableEndpointMetrics() {
    const assignMetrics = () => {
      this.deviceService.devices
        ?.filter((device) => this.deviceService.deviceIsTsdCapable(device))
        .forEach((device) => {
          this.billingStore.listAvailableEndpointMetrics(device).subscribe({
            next: (res) => {
              // write available metrics onto device
              device.availableMetrics = res[device.endpointId]
            },
            error: (err: HttpErrorResponse) => this.proficloud.logoutOnUnauthorised(err),
          })
        })
    }

    if (!this.deviceService.devices) {
      this.deviceStore.storeInitialised$.subscribe((errored) => {
        if (!errored) {
          assignMetrics()
        }
      })
    } else {
      assignMetrics()
    }
  }

  public setAndCountTsdMetrics() {
    this.tsdTotalMetricsUsed = 0
    this.tsdFlatArray = []
    // Resets the used Metrics of all devices. For when all metrics of a device get unassigned. This won´t get updated otherwise.
    this.deviceService.devices.forEach((device) => {
      device.usedMetrics = []
    })

    if (!this.tsdSubscription?.metricsUsed) {
      return
    }

    this.tsdSubscription.metricsUsed.forEach((deviceObject) => {
      Object.entries(deviceObject).forEach(([endpointId, metrics]) => {
        const device = this.deviceService.devices.find((d) => d.endpointId === endpointId)
        if (!device) {
          console.warn('this device is used in a subscription but is not found in this account', endpointId)
          return
        }

        if (!device.metadata.uuid) {
          console.warn('device has no UUID')
          return
        }

        device.usedMetrics = metrics
        this.tsdTotalMetricsUsed += metrics.length

        metrics.forEach((metric) => {
          this.tsdFlatArray.push({
            deviceName: device.metadata.deviceName,
            uuid: device.metadata.uuid as string,
            metric,
            device,
          })
        })
      })
    })

    // Set userMetrics to an empty array if not present to avoid possible interface errors later (not ideal)
    this.deviceService.devices.forEach((device) => {
      if (!device.usedMetrics) {
        device.usedMetrics = []
      }
    })
  }

  private reactToCheckoutModal() {
    this.showCheckoutForm$.pipe(filter((active) => !!active)).subscribe(() => {
      const win: any = window
      win.dataLayer = win.dataLayer || []
      win.dataLayer.push({
        event: 'purchase',
        transactionId: new Date().getTime() + '',
        transactionTotal: this.billingDetails.shoppingCart?.plan?.fields.price,
        transactionProducts: [
          {
            sku: this.billingDetails.shoppingCart?.plan?.fields.sapNumber,
            name: this.billingDetails.shoppingCart?.plan?.fields.planVariantName,
            category: this.billingDetails.shoppingCart?.plan?.fields.planName,
            price: this.billingDetails.shoppingCart?.plan?.fields.price,
            quantity: this.billingDetails.shoppingCart?.quantity || 1,
          },
        ],
      })
    })
  }

  public getContractPartner() {
    const contractPartner = this.content.content.contractPartners?.find((partner) => partner.fields.countryCode === this.billingAccount?.address.country)
    if (!contractPartner) {
      console.warn('Contract partners not found')
      return
    }
    return contractPartner.fields
  }

  public isStripeCountry(countryCode: string): boolean {
    return this.stripeCountries.includes(countryCode)
  }

  public createBillingAddressForm() {
    this.billingFormPersonalFields = []

    // pre-filled value
    let email = this.billingAccount?.emailAddress ? this.billingAccount?.emailAddress : this.proficloud.keycloakData.userDetails?.data.email
    let firstName = this.billingAccount?.firstName ? this.billingAccount?.firstName : this.proficloud.keycloakData.userDetails?.data.firstName
    let lastName = this.billingAccount?.lastName ? this.billingAccount?.lastName : this.proficloud.keycloakData.userDetails?.data.lastName

    // if street and houseNumber exists
    let street = this.billingAccount?.address.street ? this.billingAccount?.address.street + ' ' : ''
    let houseNumber = this.billingAccount?.address.houseNumber ? this.billingAccount?.address.houseNumber : ''

    this.billingFormCompanyFields = [
      {
        type: 'text',
        placeholder: 'Email',
        key: 'email',
        inputId: 'billing-email',
        // helpText: 'e.g. john@doe.com',
        control: new UntypedFormControl(email || '', [Validators.required, Validators.email]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_EMAIL_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'First Name',
        key: 'firstname',
        inputId: 'billing-first-name',
        // helpText: 'e.g. John',
        control: new UntypedFormControl(firstName || '', [Validators.required, Validators.maxLength(80)]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_FIRST_NAME_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Last Name',
        key: 'lastname',
        inputId: 'billing-last-name',
        // helpText: 'e.g. Doe',
        control: new UntypedFormControl(lastName || '', [Validators.required, Validators.maxLength(80)]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_LAST_NAME_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Company Name',
        key: 'companyname',
        inputId: 'billing-company-name',
        // helpText: 'e.g. ACME Inc.',
        control: new UntypedFormControl(this.billingAccount?.companyName || '', [Validators.required, Validators.maxLength(80)]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_COMPANY_NAME_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Address Line 1',
        key: 'addressLine1',
        inputId: 'billing-address-line-1',
        // helpText: 'e.g. Harvard Blvd.',
        control: new UntypedFormControl(this.billingAccount?.address.addressLine1 ?? street + houseNumber, [Validators.required, Validators.maxLength(64)]),
        maxLength: 64,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_ADDRESS_LINE_1_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Address Line 2',
        key: 'addressLine2',
        inputId: 'billing-address-line-2',
        // helpText: 'e.g. 144C',
        control: new UntypedFormControl(this.billingAccount?.address.addressLine2 ?? '', [Validators.maxLength(64)]),
        maxLength: 64,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_ADDRESS_LINE_2_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Postal Code',
        key: 'postal',
        inputId: 'billing-postal-code',
        // helpText: 'e.g. 10119',
        control: new UntypedFormControl(this.billingAccount?.address.postalCode || '', [Validators.required]),
        maxLength: 80,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_POSTAL_CODE_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'City',
        key: 'city',
        inputId: 'billing-city',
        // helpText: 'e.g. Youngstown',
        control: new UntypedFormControl(this.billingAccount?.address.city || '', [Validators.required]),
        maxLength: 80,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_CITY_VALIDATION'),
      },
      {
        type: 'select',
        selectValues: this.content?.content?.contractPartners?.map((country) => {
          return {
            key: country.fields.countryCode,
            value: country.fields.country,
            visible: () => this.isCountryAvailable(country.fields.countryCode),
          }
        }),
        /* selectValues: [
          { key: 'DE', value: 'Germany', visible: () => true },
          { key: 'CH', value: 'Switzerland', visible: () => true },
          { key: 'ES', value: 'Spain', visible: () => true },
          { key: 'IT', value: 'Italy', visible: () => true },
          { key: 'CL', value: 'Chile', visible: () => true },
        ], */
        placeholder: 'Country',
        value: '',
        control: new UntypedFormControl(this.billingAccount?.address.country || '', [Validators.required]),
        key: 'country',
        inputId: 'billing-country',
        // helpText: 'If your country is not available, please contact us.',
        changed: () => {
          // console.log('land changed')
        },
      },
      {
        type: 'select',
        selectValues: [],
        placeholder: 'State',
        value: '',
        key: 'state',
        inputId: 'billing-state',
        control: new UntypedFormControl(this.billingAccount?.address.state || '', [Validators.required]),
      },
      {
        type: 'text',
        placeholder: 'VAT number',
        key: 'vatNumber',
        inputId: 'billing-vat-number',
        // helpText: 'e.g. DE123456789, CHE-123.456.789',
        control: new UntypedFormControl(this.billingAccount?.vatId || '', [Validators.required, Validators.pattern(this.vatPattern)]),
        maxLength: 80,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_VAT_NUM_VALIDATION'),
      },
    ]
    // If set to false, we will add the respective field. This is the default.
    if (!this.hideCustomerNumberFieldControl.value) {
      this.billingFormCompanyFields.push({
        type: 'text',
        placeholder: 'Phoenix Contact Customer ID',
        key: 'pxcCustomerId',
        inputId: 'billing-pxc-customer-id',
        // helpText: 'e.g. PQRSTXYZ',
        control: new UntypedFormControl(this.billingAccount?.pxcCustomerId || '', [Validators.required, Validators.minLength(1)]),
        maxLength: 80,
      })
    }

    const formObject: Record<string, UntypedFormControl> = {}
    ;[...this.billingFormCompanyFields, ...this.billingFormPersonalFields].forEach((element) => {
      formObject[element.key] = element.control
    })

    // set prefillable values
    if (!this.billingAccount) {
      formObject['companyname'].setValue(this.proficloud.currentOrganisation?.organizationName || '')
      formObject['firstname'].setValue(this.proficloud.keycloakData?.userDetails?.data?.firstName || '')
      formObject['lastname'].setValue(this.proficloud.keycloakData?.userDetails?.data?.lastName || '')
    }

    this.billingAddressForm = new UntypedFormGroup(formObject)
  }

  private isCountryAvailable(countryCode: string) {
    const keyMap: { [key in Environments]: 'showOnDevelopment' | 'showOnProduction' } = {
      local: 'showOnDevelopment',
      dev: 'showOnDevelopment',
      staging: 'showOnDevelopment',
      production: 'showOnProduction',
    }
    const key = keyMap[this.app.environment.frontend]
    const available = this.content.content.contractPartners?.find((country) => {
      return country.fields.countryCode === countryCode && country.fields[key]
    })
    return available ? true : false
  }

  spanishVAT = '^ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$'

  germanVAT = '^(DE)[0-9]{9}$'

  italianVAT = '^(IT)[0-9]{11}$'

  swissVAT1 = '^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3}$'

  chileVAT = '^.*$'

  chinaVAT = '^.*$'

  usVAT = '^[0-9]{2}[0-9]{7}$'
  // swissVAT2 = "^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3} (MWST)$"
  // swissVAT3 = "^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3} (TVA)$"
  // swissVAT4 = "^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3} (IVA)$"

  vatPattern = [this.spanishVAT, this.germanVAT, this.italianVAT, this.swissVAT1, this.chileVAT, this.chinaVAT, this.usVAT].join('|')

  orderReferenceControl = new UntypedFormControl()

  referenceInput = ''

  upgradeReferenceControl = new UntypedFormControl()

  upgradeReference = ''

  /**    BILLING ACCOUNT   */

  public getCountryLanguageCode(countryCode: string) {
    const country = this.content.content.contractPartners?.find((country) => country.fields.countryCode === countryCode)
    if (!country) {
      console.warn('Country not found')
      return
    }
    console.log('setting lang to ', country.fields.languageCode)
    return country ? country.fields.languageCode : 'en'
  }

  /**     SUBSCRIPTIONS     */

  /**
   *    Add subscription to cart
   */
  public addSubscriptionToCart(options: { service: ContentfulService; plan: ContentfulPlanVariant; quantity: number }) {
    this.billingDetails.shoppingCart = options
  }

  /**
   *    get package helpers
   */
  public getTsdPackageById(id: string) {
    return this.availableTsdPackages.find((pack) => pack.id === id)
  }

  // todo: add available emma packages
  public getPackageById(id: string) {
    return [...(this.emmaPackages || []), ...(this.availableTsdPackages || []), ...(this.availableIaPackages || [])].find((pack) => pack.id === id)
  }

  public countPackageById(id: string) {
    return [this.tsdSubscription, ...this.impulseAnalyticsSubscriptions].filter((sub) => sub.bookedPackageId === id).length
  }

  // TODO: This needs to be replaced by a pipe that only gets called once the necessary api calls have been made
  // Just now it's calling all the time from the template which is awful.
  public getButtonDetails(service: ContentfulService, plan: ContentfulPlanVariant) {
    // EMMA specific restrictions
    const deprecatedEMMAPackges = ['1318899', '1318901', '1270205', 'EMMAXXL']
    const emmaExtensions = ['1768808', '1768809', '1768811', '1768815']
    if (plan.fields.planName === 'EMMA Service') {
      // Disable old packages
      if (deprecatedEMMAPackges.includes(plan.fields.sapNumber)) {
        return { wording: 'book package', disabled: true }
      }

      // Disable extensions if we don't already have a pro package
      if (this.emsSubscriptions && emmaExtensions.includes(plan.fields.sapNumber)) {
        const hasProPackage = this.emsSubscriptions
          .map((s) => {
            return this.getPackageById(s.bookedPackageId)?.name === 'EMS - PRO LICENCE'
          })
          .reduce((prev, curr) => {
            return prev || curr
          }, false)

        return { wording: 'book package', disabled: hasProPackage ? this.isDisabled(plan) : true }
      }

      // Disable if we already have one and multiples not allowed
      if (this.emsSubscriptions && !plan.fields.multipleOrderAllowed) {
        const hasAlready = this.emsSubscriptions
          .map((s) => {
            return this.getPackageById(s.bookedPackageId)?.id === plan.fields.sapNumber
          })
          .reduce((prev, curr) => {
            return prev || curr
          }, false)

        if (hasAlready) {
          return { wording: 'your plan', disabled: true }
        }
      }
    }

    // Impulse analytics specific restrictions
    if (plan.fields.planName === 'ImpulseAnalytics' && !this.tsdSubscription) {
      return { wording: 'not loaded', disabled: true }
    }

    // Other services (default behaviour)
    if (plan.fields.price === 0) {
      return { wording: 'go to service', disabled: false }
    }

    if (service.fields.id === 'tsd') {
      // check if this is your plan varient
      if (plan.fields.sapNumber === this.tsdSubscription.bookedPackageId) {
        return { wording: 'your plan', disabled: true }
      }
    }

    return { wording: 'book package', disabled: this.isDisabled(plan) }
  }

  private isDisabled(plan: ContentfulPlanVariant): boolean {
    return !plan.fields.preview || !this.authorization.isAdmin(this.proficloud.currentOrganisation)
  }
}
